<template>
  <main style="padding-top: 100px;">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1>Resetare parola</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="margin_30_20">
      <b-row>
        <b-col offset-md="4" md="4">
          <div class="form-group">
            <label>Parola Noua</label>
            <b-input type="password" v-model="password" placeholder="Parola noua (obligatoriu)"></b-input>
          </div>
          <div class="form-group">
            <label>Confirmare Parola</label>
            <b-input type="password" v-model="c_password" placeholder="Confirmare Parola (obligatoriu)"></b-input>
          </div>
        </b-col>
        <b-col offset-md="4" md="4">
          <b-button variant="primary" @click="setNewPassword">SETEAZA PAROLA NOUA</b-button>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
import Auth from '@/components/auth'

export default {
  name: 'ResetPassword',
  data() {
    return {
      isBusy: false,
      token: this.$route.params.token,
      password: '',
      c_password: '',
    }
  },
  methods: {
    setNewPassword() {
      const postData = {
        token: this.token,
        password: this.password,
        c_password: this.c_password
      }
      Auth.setNewPassword(postData).then(response => {
        this.$swal(
            'Parola a fost resetata',
            'Te poti autentifica in contul tau folosing noua parola',
            'success'
        )
        this.$router.push({name: 'home'})
      }).catch(() => {
        this.$swal(
            'Parola nu poate fi resetata',
            'Campurile pentru parola trebuie sa fie identice',
            'error'
        )
      })
    }
  }
}
</script>

